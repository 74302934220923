import { useState, useEffect } from 'react';

import { getMobileOperatorList, getMobileZoneList, getPlanDetails, mobileRechargeRequest } from '../../utils/api';
import { isEmpty } from '../../utils/common';
import SelectOprater from '../../Components/SelectOprater';
import IconClose from '../../assets/images/IconClose';
import OfferBanner from '../../Components/OfferBanner';
import { TabContentWrapper, PlanWrapper, YellowStripWrapper, AlertWrapper, TableWrapper } from '../UI/styledConstants';
import Loader from '../UI/Loader/Loader';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import { useLocation } from 'react-router-dom';

export default function Component({ className = '' }) {
	const [planDetails, setPlanDetails] = useState(null);
	const [activeMobilePop, setActiveMobilePop] = useState(false);
	const [activePlan, setActivePlan] = useState('talktime');
	const [message, setMessage] = useState({});
	const [rechargeType, setRechargeType] = useState('PREPAID');
	const [provider, setProvider] = useState('');
	const [formDetails, setFormDetails] = useState({
		operatorCode: '',
		phoneNumber: '',
		rechargeAmount: '',
		type: 'MOBILE',
		zone: '',
	});

	const [operatorList, setOperatorList] = useState([]);
	const [zoneList, setZoneList] = useState([]);
	const [loading, setLoading] = useState(false);
	const numberReg = /^[0-9]+$/;
	// const nameReg = /^[A-Za-z\s]+$/i;
	const location = useLocation();

	const rechargeFormHandler = (event) => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
			return;
		}
		if (event.target.name === 'rechargeAmount' || event.target.name === 'phoneNumber') {
			if (!numberReg.test(event.target.value) && event.target.value) {
				return false;
			}
		}

		setFormDetails({
			...formDetails,
			[event.target.name]: event.target.value,
		});
	};

	const handleFormPlan = (data) => {
		setActiveMobilePop(false);
		setFormDetails({
			...formDetails,
			rechargeAmount: data,
		});
	};

	const handleOptionChange = (changeEvent) => {
		setRechargeType(changeEvent.target.value);
	};

	useEffect(() => {
		if (!isEmpty(sessionStorage.getItem('ID Token'))) {
			setLoading(true);
			getMobileOperatorList().then((data) => {
				setLoading(false);
				if (data?.success) {
					setOperatorList(data?.data);
				}
			});

			getMobileZoneList().then((data) => {
				if (data?.success) {
					setZoneList(data?.data);
				}
			});
		}
	}, [sessionStorage.getItem('ID Token')]);

	useEffect(() => {
		const prov = provider.split(',');

		setFormDetails({
			...formDetails,
			operatorCode: prov[0],
		});
	}, [provider]);

	const planHandler = async () => {
		const prov = provider.split(',');
		if (formDetails.operatorCode === '') {
			setMessage({ type: 'error', msg: 'Please select operator', place: 'local' });
			return;
		} else if (formDetails.zone === '') {
			setMessage({ type: 'error', msg: 'Please select circle', place: 'local' });
			return;
		}
		setLoading(true);
		const params = { zone: formDetails.zone, operator: prov[1] };
		const planData = await getPlanDetails(params);
		setLoading(false);
		setPlanDetails(planData?.data || null);
		if (!isEmpty(planData?.data)) {
			setActiveMobilePop(true);
		}
	};

	const rechargeHandler = () => {
		if (formDetails.phoneNumber === '' || formDetails.phoneNumber.length != 10) {
			setMessage({ type: 'error', msg: 'Please enter correct Mobile Number', place: 'local' });
			return;
		} else if (formDetails.operatorCode === '') {
			setMessage({ type: 'error', msg: 'Please select operator', place: 'local' });
			return;
		} else if (formDetails.zone === '') {
			setMessage({ type: 'error', msg: 'Please select circle', place: 'local' });
			return;
		} else if (formDetails.rechargeAmount === '') {
			setMessage({ type: 'error', msg: 'Please input amount', place: 'local' });
			return;
		}
		setLoading(true);

		const param = {
			operatorCode: formDetails.operatorCode,
			phoneNumber: formDetails.phoneNumber,
			rechargeAmount: formDetails.rechargeAmount,
			type: formDetails.type,
		};
		mobileRechargeRequest(param).then((data) => {
			setLoading(false);

			if (data?.success) {
				setMessage({ type: 'success', msg: data?.msg, place: 'globle' });
			} else {
				if (data?.errorCodeList?.length > 0) {
					setMessage({ type: 'error', msg: data?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: data?.msg, place: 'globle' });
				}
			}
		});
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	const queryParams = new URLSearchParams(location.search);
	const paramValue = queryParams.get('type');
	useEffect(() => {
		if (!isEmpty(paramValue)) {
			if (paramValue === 'mobile') {
				setFormDetails({
					operatorCode: '',
					phoneNumber: '',
					rechargeAmount: '',
					type: 'MOBILE',
					zone: '',
				});
			}
		}
	}, [paramValue]);

	return (
		<>
			<TabContentWrapper className={`tab-content-wrapper ${className}`}>
				{!isEmpty(message) && message.place === 'globle' && (
					<AlertWrapper className={`${message.type} globle`}>
						<span className="close" onClick={() => setMessage({})}>
							×
						</span>
						{message.msg}
					</AlertWrapper>
				)}
				<div className="tab-content-left">
					<h2>Mobile Recharge</h2>
					<div className="form-field">
						<div className="radio-group" onChange={rechargeFormHandler}>
							<label className="radio" style={{ marginRight: '40px' }}>
								<input type="radio" name="rechargeType" value="PREPAID" checked={rechargeType === 'PREPAID'} onChange={handleOptionChange} /> Prepaid
							</label>
							<label className="radio">
								<input type="radio" name="rechargeType" value="POSTPAID" checked={rechargeType === 'POSTPAID'} onChange={handleOptionChange} /> Postpaid
							</label>
						</div>
					</div>
					<div className="form-field">
						<input type="text" name="phoneNumber" placeholder="Phone Number" maxLength={10} value={formDetails.phoneNumber} onChange={rechargeFormHandler} />
					</div>

					<div className="form-field">
						<CustomDropdown list={operatorList} setProvider={setProvider} type="mobile" setMessage={setMessage} />
					</div>
					<div className="form-field">
						<select className="item-select" name="zone" onChange={rechargeFormHandler}>
							<option>Select Circle</option>
							{zoneList.map((zone, i) => {
								return (
									<option key={i} value={zone.zoneCode}>
										{zone.zoneName}
									</option>
								);
							})}
						</select>
					</div>
					<div className="form-field">
						<input type="text" name="rechargeAmount" placeholder="Amount" value={formDetails.rechargeAmount} onChange={rechargeFormHandler} />
						<div className="browse-plans" onClick={planHandler}>
							Browse Plans
						</div>
					</div>
					{!isEmpty(message) && message.place === 'local' && (
						<AlertWrapper className={message.type}>
							<span className="close" onClick={() => setMessage({})}>
								×
							</span>
							{message.msg}
						</AlertWrapper>
					)}

					<button type="submit" className="button-outline" onClick={rechargeHandler}>
						PROCEED TO RECHARGE
					</button>
				</div>
				<div className={`tab-content-right tab-content-right-mobile ${!activeMobilePop ? '' : 'active'}`}>
					<div
						className="d-m-block close-pop"
						onClick={() => {
							setActiveMobilePop(false);
						}}
					>
						<IconClose />
					</div>
					{!isEmpty(planDetails) ? (
						<PlanWrapper>
							<div className="plan-tabs-wrapper ">
								{Object.keys(planDetails).map((key, i) => (
									<div key={i} className={`${activePlan === key ? 'active' : ''} plan-tab`} onClick={() => setActivePlan(key)}>
										{key}
									</div>
								))}
							</div>

							<TableWrapper className="mobile-plan-table">
								<table className="table">
									<thead className="table-head">
										<tr>
											<th>Sr No</th>
											<th>Amount</th>
											<th>benefit</th>
											<th>Validity</th>
											<th className="text-right">Action</th>
										</tr>
									</thead>
									<tbody className="table-body">
										{!isEmpty(activePlan) &&
											planDetails[activePlan].map((data, i) => {
												return (
													<tr key={i}>
														<td>{i + 1}</td>
														<td>&#8377; {data.rs}</td>
														<td>
															&#8377; {data.rechargeTalktime || 0} {data.rechargeType}
														</td>
														<td>{data.validity}</td>
														<td>
															<div className="select-link flex justify-end">
																<div onClick={() => handleFormPlan(data.rs)}>&#8377; {data.rs}</div>
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</TableWrapper>
						</PlanWrapper>
					) : (
						<>{!loading && <OfferBanner />}</>
					)}
				</div>
				{loading && <Loader />}
			</TabContentWrapper>
			<YellowStripWrapper className="yellow-strip">
				<strong>No Wallet KYC Required </strong> 😊to pay using UPI on Swagat Enterprises . <strong> Learn more.</strong>
			</YellowStripWrapper>
			<SelectOprater />
		</>
	);
}
